'use client';

import { forwardRef, useContext, useMemo } from 'react';

import { Role } from '@ariakit/react';
import cx from 'classnames';

import { Breakpoint } from '~/ui/styles/grid';

import styles from './column.module.scss';
import { ColumnContext, GridContext } from '../context';

export interface ColumnProps
  extends React.ComponentPropsWithoutRef<typeof Role>,
    Partial<Record<Breakpoint, number>> {
  offsetRight?: Partial<Record<Breakpoint, number>>;
  offsetLeft?: Partial<Record<Breakpoint, number>>;
}

export const Column = forwardRef(ColumnImpl);
function ColumnImpl(
  // TODO: Having sm, md, and lg hardcoded kind of ruins the point of having the Breakpoint enum
  { sm, md, lg, offsetLeft, offsetRight, className, children, ...rest }: ColumnProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const gridContext = useContext(GridContext);
  const columnContext = useContext(ColumnContext);

  return (
    <Role
      className={cx(
        styles.column,
        {
          [styles.allowOverflow]: gridContext.overflow,
        },
        className,
      )}
      style={{
        // we don't need to set the CSS variable if it is the same as the parent width because it
        //  will be inherited
        ['--span-sm' as never]: sm === columnContext[Breakpoint.SM] ? undefined : sm,
        ['--span-md' as never]: md === columnContext[Breakpoint.MD] ? undefined : md,
        ['--span-lg' as never]: lg === columnContext[Breakpoint.LG] ? undefined : lg,
        ['--ol-sm' as never]: offsetLeft?.[Breakpoint.SM],
        ['--ol-md' as never]: offsetLeft?.[Breakpoint.MD],
        ['--ol-lg' as never]: offsetLeft?.[Breakpoint.LG],
        ['--or-sm' as never]: offsetRight?.[Breakpoint.SM],
        ['--or-md' as never]: offsetRight?.[Breakpoint.MD],
        ['--or-lg' as never]: offsetRight?.[Breakpoint.LG],
      }}
      ref={ref}
      {...rest}
    >
      <ColumnContext.Provider
        value={useMemo(
          () => ({
            [Breakpoint.SM]: sm ?? columnContext[Breakpoint.SM],
            [Breakpoint.MD]: md ?? columnContext[Breakpoint.MD],
            [Breakpoint.LG]: lg ?? columnContext[Breakpoint.LG],
          }),
          [columnContext, lg, md, sm],
        )}
      >
        <GridContext.Provider value={{ overflow: false }}>{children}</GridContext.Provider>
      </ColumnContext.Provider>
    </Role>
  );
}
