import cx from 'classnames';

import { useFilterContext } from '~/v1/components/filter/filter.context';
import { LayoutClient } from '~/v1/system/layout/client/client';

import { ListItemSkeleton } from './item/item.skeleton';
import styles from './list.module.scss';

interface ListProps {
  children: React.ReactNode;
  className?: string;
}

export const List: React.FC<ListProps> = ({ children, className }) => {
  const { isLoading, isLoadingMore } = useFilterContext();

  const loadMoreSkeleton = Array.from(Array(16)).map((_, index) => (
    <li className={styles.listItem} key={index}>
      <ListItemSkeleton />
    </li>
  ));

  return (
    <LayoutClient>
      <ul className={cx(styles.list, className)}>
        {!isLoading && children}
        {(isLoading || isLoadingMore) && loadMoreSkeleton}
      </ul>
    </LayoutClient>
  );
};
