import { useState } from 'react';

import cx from 'classnames';
import { ReactSVG } from 'react-svg';

import { Column, Grid } from '~/ui/components/grid';
import { Breakpoint } from '~/ui/styles/grid';
import { FullBleedCTA, FullBleedCTATrigger } from '~/v1/components/fullBleedCta/fullBleedCta';
import { Icon } from '~/v1/components/icons/icon';
import { IconType } from '~/v1/components/icons/icon.interfaces';
import { Image } from '~/v1/components/image/image';
import { Link } from '~/v1/components/link/link';

import { type ListItemType } from './item.interface';
import styles from './item.module.scss';
import { SkeletonContent } from './item.skeleton';

type ListItemProps = ListItemType;

export const ListItem: React.FC<ListItemProps> = ({
  title,
  description,
  image,
  captions,
  hasVideo,
  pageLink,
  isExternal,
}) => {
  const [isLoading, setIsLoading] = useState(!!image);

  return (
    <FullBleedCTA label={isLoading ? 'Loading' : title!} as="li" className={styles.link}>
      <div className={styles.focus} />
      <Grid className={styles.root}>
        {isLoading ? (
          <SkeletonContent />
        ) : (
          <Column offsetLeft={{ lg: 1 }} sm={5} md={6} lg={5} className={styles.content}>
            <h3 className={cx(styles.title, 'modulesTitleTypography truncate-3')}>{title}</h3>
            {description && (
              <p className={cx(styles.description, 'paragraphTypography')}>{description}</p>
            )}
            {captions?.map((caption, index) => (
              <p key={index} className={cx(styles.caption, 'paragraphTypography')}>
                <span>{caption[0]}</span>
                {caption[1]}
              </p>
            ))}
            <FullBleedCTATrigger>
              <Link
                href={pageLink}
                withIcon={isExternal}
                className={isExternal ? styles.externalLink : undefined}
              >
                {isExternal ? 'Read the news' : null}
              </Link>
            </FullBleedCTATrigger>
          </Column>
        )}
        {image && (
          <Column
            offsetLeft={{ lg: 1 }}
            sm={2}
            md={1}
            lg={1}
            className={cx(styles.imageCol, {
              [styles.skeletonImage]: isLoading,
            })}
          >
            {image.src.endsWith('.svg') ? (
              <div className={styles.logoWrapper}>
                <ReactSVG
                  src={image.src}
                  title={image.alt}
                  afterInjection={() => setIsLoading(false)}
                />
              </div>
            ) : (
              <Image
                className={cx(styles.image, {
                  [styles.imageWithShading]: hasVideo,
                })}
                {...image}
                colSpan={{ [Breakpoint.SM]: 2, [Breakpoint.MD]: 1 }}
                onLoad={() => setIsLoading(false)}
              >
                {hasVideo && !isLoading && (
                  <Icon className={styles.videoThumbnail} type={IconType.Play} />
                )}
              </Image>
            )}
          </Column>
        )}
      </Grid>
    </FullBleedCTA>
  );
};
