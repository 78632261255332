'use client';

import { resolveHref } from 'next/dist/client/resolve-href';
import Link from 'next/link';
import Router from 'next/router';
import { forwardRef } from 'react';
import { type UrlObject } from 'url';

import cx from 'classnames';

import { type TrackEventProps } from '~/ui/components/analytics';
import { useIsFullBleedCTATrigger } from '~/v1/components/fullBleedCta/fullBleedCta.utils';
import { type AsyncFunc, useAsync } from '~/v1/hooks/useAsync';
import { useThemeContext } from '~/v1/system/theme/theme.context';
import { hexToRGB } from '~/v1/system/theme/theme.utils';
import { isInternalLink } from '~/v1/utils/isInternalLink';

import { ButtonMode, ButtonSize, ButtonType } from './button.interface';
import styles from './button.module.scss';
import { Icon } from '../icons/icon';
import { IconType } from '../icons/icon.interfaces';

type ButtonProps = {
  type?: ButtonType;
  mode?: ButtonMode;
  isTransparent?: boolean;
  isAsync?: boolean;
  size?: ButtonSize;
  text?: string;
  // @deprecated use `aria-label` instead
  ariaLabel?: string;
  disabled?: boolean;
  className?: string;
  href?: string | UrlObject;
  // TODO: This whole component needs to be reworked. `as` is a NextLink prop
  as?: string | UrlObject;
  /* Call `trackEvent` when the link is clicked */
  event?: TrackEventProps;
};

export const Button = forwardRef(ButtonImpl);
function ButtonImpl(
  {
    type = ButtonType.Primary,
    mode,
    isTransparent = false,
    isAsync = false,
    size = ButtonSize.Large,
    text,
    ariaLabel,
    className,
    href,
    children,
    disabled,
    onClick,
    event,
    ...props
  }: ButtonProps & React.HTMLAttributes<HTMLButtonElement | HTMLAnchorElement | HTMLDivElement>,
  forwardedRef: React.Ref<HTMLButtonElement | HTMLAnchorElement | HTMLDivElement>,
) {
  const { isLoading, async } = useAsync();
  const theme = useThemeContext();
  const themeMode = hexToRGB(theme.copy) === '255, 255, 255' ? ButtonMode.Dark : ButtonMode.Light;
  const isFullBleed = useIsFullBleedCTATrigger(props);

  const buttonMode = mode || themeMode;
  const buttonClasses = cx(
    styles.button,
    className,
    'buttonTypography',
    styles[`button-${type}-${buttonMode}`],
    styles[`button-${type}`],
    styles[`button-${size}`],
    {
      [styles.buttonTransparent]: isTransparent,
      [styles.isNotFullBleed]: !isFullBleed,
    },
  );

  const TagName = href ? Link : onClick ? 'button' : 'div';
  const asyncOnClick = async(onClick as AsyncFunc);

  const renderContent = () => {
    return isLoading ? (
      <Icon type={IconType.Loading} className="loading" />
    ) : (
      <>
        {text && <p>{text}</p>}
        {children}
      </>
    );
  };

  const stringHref = typeof href === 'object' ? resolveHref(Router, href) : href;
  const isInternal = stringHref ? isInternalLink(stringHref) : false;

  return (
    <TagName
      aria-label={ariaLabel ?? text}
      className={buttonClasses}
      href={href ?? ''}
      event={event}
      target={isInternal ? undefined : '_blank'}
      rel={isInternal ? undefined : 'noreferrer'}
      disabled={disabled || isLoading}
      onClick={isAsync ? asyncOnClick : onClick}
      // @ts-expect-error This whole component needs to be refactored to use correct types
      ref={forwardedRef}
      {...props}
    >
      {renderContent()}
    </TagName>
  );
}
