'use client';

import { createContext } from 'react';

import { Columns } from '~/ui/styles/grid';

export interface GridContext {
  overflow: boolean;
}
export const GridContext = createContext<GridContext>({
  overflow: false,
});

// This is necessary to allow us to render the provider in <Grid>, which is a server component
export function GridContextProvider(props: React.ComponentProps<typeof GridContext.Provider>) {
  return <GridContext.Provider {...props} />;
}

export const ColumnContext = createContext(Columns);
