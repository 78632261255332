import { forwardRef } from 'react';

import { Role } from '@ariakit/react';
import cx from 'classnames';

import { GridContextProvider } from './context';
import styles from './grid.module.scss';

export interface GridProps extends React.ComponentPropsWithoutRef<typeof Role> {
  /**
   * Removes horizontal page margins from the grid on small and medium breakpoints
   *
   * Note: this only has effect on the top-level grid. Nested grids will never have page margins
   *
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Allows all immediate (`<Column>`) children to be wider than this grid. `<Grid>` children of
   * that column will have its full width available.
   *
   * @default false
   */
  overflow?: boolean;
}

export const Grid = forwardRef(GridImpl);
function GridImpl(
  { fullWidth, overflow, children, className, ...rest }: GridProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <Role
      className={cx(
        styles.grid,
        {
          [styles.fullWidth]: fullWidth,
        },
        className,
      )}
      ref={ref}
      {...rest}
    >
      <GridContextProvider value={{ overflow: !!overflow }}>{children}</GridContextProvider>
    </Role>
  );
}
